import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Seo from '../components/seo'

const Legal = () => (
  <Layout>
    <Seo title="Legal" />
    <div className="container">
      <div className="about-hero">
        <StaticImage
          src="../images/logo.svg"
          alt="BM Creative Works"
          className="menu-logo"
        />{' '}
      </div>
      <div className="legalpage" style={{ marginTop: '50px' }}>
        <h2>Gizlilik ve Çerezler</h2>
        <div>
          <h3>Gizlilik</h3>
          <p>
            Kişilerimizden gelen kişisel verileri işleriz. Siz de bu gruba
            dahilsiniz. Bunu size mümkün olduğunca yardımcı olmak için
            yapıyoruz. Bu gizlilik bildiriminde, kişisel verilerinizi neden ve
            nasıl işlediğimizi açıklıyoruz. Bununla ilgili herhangi bir sorunuz
            varsa, lütfen bizimle hello@bmcreative.works adresinden iletişime
            geçin.
          </p>
        </div>
        <div>
          <h4>Hangi verileri işleyebiliriz?</h4>
          <p>
            Bizimle iletişime geçerseniz, adınızı (buraya girdiğiniz ad ne
            olursa olsun), telefon numaranızı, e-posta adresinizi, internet
            sitenizi, LinkedIn sayfanızı ve bize gönderdiğiniz tüm ekleri
            işleyeceğiz. Ayrıca, web sitemizde IP adresinizi, çerez kimliğinizi
            ve gezinme davranışınızı işliyoruz. Ancak işleri çok zorlaştırmamak
            için bir iletişim formumuz yok.
          </p>
        </div>
        <div>
          <h4>
            Neden en sevdiğimiz sorulardan biridir. Peki bu verileri neden
            işliyoruz?
          </h4>
          <p>
            Bize ihtiyacınız varsa (veya bizim size ihtiyacımız varsa), size
            ulaşabilmemizde fayda var. Mantıklı gibi geliyor, değil mi? Sizden
            iletişim formumuz aracılığıyla aldığımız bilgileri yalnızca bu
            nedenle kullanırız. Ayrıca web sitemizde ne yaptığınızı görmek
            isteriz. Hangi sayfaları okuyorsunuz? Bu sayfaları okumak ne kadar
            sürüyor? Elbette bunlar bilmemiz gereken çok ilginç şeyler! Bu
            bilgilerle web sitemizin içeriğini iyileştirebilir ve
            ihtiyaçlarınıza daha iyi uyacak şekilde uyarlayabiliriz. Bu ikimizi
            de mutlu eder.
          </p>
        </div>
        <div>
          <h4>Saklama süresi</h4>
          <p>
            Kişisel verileri gerekenden daha uzun süre saklamayız. Bunu bir
            temiz masa politikası olarak düşünün. Aldığımız verileri
            hizmetlerimizi iyileştirmek için bir yıl süreyle saklıyoruz. Bize
            bir iletişim formu aracılığıyla herhangi bir kişisel bilgi
            verirseniz, sorunuz veya yorumunuzla ilgili olarak size yardımcı
            olur olmaz bu bilgileri kaldıracağız.
          </p>
        </div>
        <h3>Çerezler</h3>
        <p>
          Bu web sitesinde çerezler ve benzer teknolojiler kullanıyoruz. Bunu
          yalnızca web sitesinin çalışmasını sağlamak ve kullanımını analiz
          etmek için yapıyoruz. Bu analizle kullanıcı deneyiminizi tekrar
          iyileştirmeyi deneyebiliriz. Burada sadece iyi şeyler oluyor!
        </p>
        <div>
          <h4>Ne tür çerezler?</h4>
          <p>
            Yalnızca sitenin düzgün çalışması için kullanılan gerekli çerezleri,
            istatistiklere odaklanmış çerezleri kullanıyoruz. Web sitemizde
            anonim ziyaretçilerin neler yaptığını görebilir ve herhangi bir
            sorunu işaret edebiliriz. Umalım ki bu sorunları gerçek bir sorun
            haline gelmeden çözebilelim.
          </p>
        </div>
        <div>
          <h4>Çerezleri istemiyor musunuz?</h4>
          <p>
            Evet, buraya kadar okuduğunuza göre hiçbir çerezi istemiyorsunuz.
            İnternet tarayıcınızı bu sitede çerezleri engellenecek şekilde
            ayarlayabilirsiniz. Bunu nasıl yapacağınız tarayıcıya göre değişir,
            ancak çoğu tarayıcı bunu yardım alanında açıklar.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default Legal
